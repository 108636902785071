<template>
  <base-modal :show="true" @close="$emit('close')">
    <div class="py-4">
      <p>Change vet for appointment #{{ appointment.id }}</p>
      <div class="flex items-center justify-between border-b py-4">
        <div class="text-lg">
          <p class="font-semibold">
            {{ format(new Date(booking.booking_datetime), 'yyyy-MM-dd HH:mm') }}
            -
            <span
              v-if="
                appointment.animal &&
                appointment.animal.name &&
                appointment.animal.animal_type
              "
            >
              {{ appointment.animal.name }} ({{
                appointment.animal.animal_type.name
              }})
            </span>
          </p>
          <p class="mt-1">
            {{ appointment.user.first_name }} {{ appointment.user.last_name }}
          </p>
        </div>
      </div>
      <div v-show="loadingState === 'idle'" class="flex border-b py-4">
        <input
          ref="searchStr"
          v-model="searchStr"
          class="placholer:text-gray-900 border-gray-light focus:outline-none focus:bg-gray-lightest w-2/3 rounded border py-3 px-2 font-semibold focus:border-gray-800"
          type="search"
          placeholder="Search veterinarian.."
        />
      </div>
      <div class="flex h-half-screen flex-col overflow-hidden">
        <div
          v-if="loadingState === 'loading'"
          class="flex h-full flex-col items-center justify-center"
        >
          <base-spinner class="text-5xl" />
        </div>
        <div v-if="loadingState === 'confirm' && selectedVet" class="py-8">
          <div class="mt-4 mb-10 flex flex-wrap justify-center">
            <div class="w-full text-center">Change vet to</div>
            <div class="mt-2 block text-center text-xl font-semibold">
              {{ selectedVet.name }}
            </div>
            <div
              v-if="!scheduledVetsId.includes(selectedVet.id)"
              class="mt-2 block w-full text-center text-red-600"
            >
              (Not available)
            </div>
          </div>
          <div class="mt-20 flex justify-between">
            <button
              class="mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-900 hover:border-red-500"
              @click="cancelConfirm"
            >
              Cancel
            </button>
            <button
              class="w-1/2 rounded border border-blue-500 bg-gray-900 px-2 py-2 text-white hover:bg-gray-800"
              @click="submitRescheduling()"
            >
              Confirm
            </button>
          </div>
        </div>

        <div
          v-if="loadingState === 'error'"
          class="flex h-full flex-col items-center justify-center"
        >
          <p class="mb-8 text-center font-semibold text-red-900">
            {{ errorMessage }}
            <!-- {{ selectedVet.name }} is already booked on that timeslot. -->
          </p>
          <button
            class="mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-900 hover:border-red-500"
            @click="loadingState = 'idle'"
          >
            Back
          </button>
        </div>
        <ul v-if="loadingState === 'idle'" class="mt-2 overflow-auto">
          <li
            v-for="vet in filteredVets"
            :key="vet.id"
            class="border-b py-4 pr-8 hover:bg-gray-200"
          >
            <div class="flex items-center justify-between">
              <div class="inline-flex items-center">
                <p class="ml-4 font-semibold">
                  {{ vet.display_name }}
                  <span
                    v-if="!scheduledVetsId.includes(vet.id)"
                    class="text-red-600"
                  >
                    (Not available)
                  </span>
                </p>
              </div>
              <button
                class="rounded border border-gray-900 px-2 py-2 text-gray-900 hover:border-black hover:text-black"
                type="button"
                @click="selectVet(vet)"
              >
                Select
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { format } from 'date-fns';
import { mapActions, mapState } from 'vuex';
import ScheduleApi from '@/api/modules/admin/schedule';
import { VetsApi } from '@/api';

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format,
      loadingState: 'idle',
      selectedVet: null,
      errorMessage: '',
      searchStr: '',
      scheduledVetsId: [],
      selectableVets: [],
    };
  },
  computed: {
    ...mapState('admin/schedule', ['vets']),
    filteredVets() {
      if (!this.searchStr) return this.selectableVets;
      return this.selectableVets.filter(vet => {
        return vet.display_name
          .toLowerCase()
          .includes(this.searchStr.toLowerCase());
      });
    },
  },
  mounted() {
    this.fetchAvailableVets();
    this.handleScheduledVets();
  },
  methods: {
    ...mapActions('admin/schedule', ['fetchScheduledVets']),

    async fetchAvailableVets() {
      this.loadingState = 'loading';
      const params = {
        countryId: this.booking.country.id,
        animalTypeId: this.appointment.animal.animal_type.id,
        serviceId: this.booking.service_id,
      };

      const { data } = await VetsApi.fetchAllVets(params);
      this.loadingState = 'idle';
      this.selectableVets = data;
      this.$nextTick(() => this.$refs.searchStr.focus());
    },

    async handleScheduledVets() {
      this.loadingState = 'loading';
      const params = {
        countryId: this.booking.country_id,
        animalTypeId: this.booking.appointment.animal.animal_type.id,
        serviceId: this.booking.service_id,
      };
      const scheduledVets = await this.fetchScheduledVets(params);
      const scheduledVetsId = scheduledVets?.map(vet => vet.id);
      this.loadingState = 'idle';
      this.scheduledVetsId = scheduledVetsId;
      this.$nextTick(() => this.$refs.searchStr.focus());
    },

    selectVet(vet) {
      this.loadingState = 'confirm';
      this.selectedVet = vet;
    },
    async submitRescheduling() {
      this.loadingState = 'loading';
      this.errorMessage = '';
      await ScheduleApi.updateBooking(this.booking.id, {
        service_id: this.booking.service_id,
        user_id: this.selectedVet.id,
        country_id: this.booking.country.id,
        booking_datetime: this.booking.booking_datetime,
      }).catch(e => {
        this.loadingState = 'error';
        this.errorMessage = e.message;
        throw Error(e);
      });

      this.loadingState = 'idle';
      this.$notify({
        group: 'primary',
        title: 'New veterinarian assigned',
        text: `Assigned ${this.selectedVet.name} to the appointment`,
      });
      this.selectedVet = null;
      this.$emit('re-scheduled');
    },
    cancelConfirm() {
      this.selectedVet = null;
      this.loadingState = 'idle';
    },
  },
};
</script>
