<template>
  <div
    class="min-w-md flex h-full flex-col justify-between overflow-hidden rounded-lg border bg-white text-gray-900 shadow"
  >
    <div class="overflow-auto p-4">
      <div class="flex items-center justify-between text-gray-700">
        <div>
          <span v-if="appointment && loadState !== 'loading'" class="font-mono"
            >#{{ appointment.id }}</span
          >
        </div>
        <span v-if="inScheduler">
          <button color="cancel" size="sm" @click="$emit('closeinfo')">
            <fv-icon class="text-gray-600" icon="chevron-right" />
          </button>
        </span>
      </div>
      <div
        class="flex flex-col justify-center pt-4"
        :class="{ 'text-sm': inScheduler }"
      >
        <div v-if="loadState === 'loading'" class="mx-auto mt-8">
          <base-spinner class="mx-auto text-3xl" />
        </div>
        <div
          v-if="booking && loadState === 'idle'"
          class="flex flex-col justify-between"
        >
          <div class="border-b border-dotted pb-4">
            <p class="mb-1 text-lg font-black">
              {{ format(new Date(booking.booking_datetime), 'HH:mm') }}
            </p>
            <div class="text-gray-700" :class="{ 'text-xs': inScheduler }" />
            <p>
              {{
                format(new Date(booking.booking_datetime), 'EEEE, MMMM do yyyy')
              }}
            </p>
            <p v-if="appointment.case_type">
              {{ appointment.case_type.name }}
            </p>
          </div>
          <div v-if="inScheduler" class="py-2">
            <div class="flex items-center" :class="{ 'text-xs': inScheduler }">
              <button
                class="focus:outline-none mr-4 font-bold text-primary-darker hover:text-primary-darkest"
                @click="reschedule = true"
              >
                Reschedule
              </button>
              <button
                class="focus:outline-none font-bold text-primary-darker hover:text-primary-darkest"
                @click="changeVet = true"
              >
                Change vet
              </button>
            </div>
          </div>

          <div
            class="mt-2 flex text-gray-800"
            :class="{ 'text-xs': inScheduler }"
          >
            <span class="inline-flex items-center space-x-1">
              <span v-if="appointment.journal_status">Signed</span>
              <span v-if="!appointment.journal_status">Not signed</span>
              <fv-icon
                size="xs"
                :class="
                  appointment.journal_status
                    ? 'text-green-600'
                    : 'text-yellow-600'
                "
                :icon="
                  appointment.journal_status ? 'check-mark-alt' : 'warning'
                "
              />
            </span>
          </div>

          <div class="pt-4">
            <div class="mt-2">
              <div v-if="booking.veterinary" class="mt-2">
                <p class="font-semibold">
                  <span v-if="booking.veterinary.display_name">{{
                    booking.veterinary.display_name
                  }}</span>
                  <span v-else
                    >{{ booking.veterinary.first_name }}
                    {{ booking.veterinary.last_name }}</span
                  >
                </p>
                <p>{{ booking.veterinary.mobile_phone_formatted }}</p>
                <p>{{ booking.veterinary.email }}</p>
                <div v-if="appointment.vet_online" class="mt-2 text-sm">
                  <p class="mb-1 text-gray-700">
                    Online at
                    <span class="rounded border bg-gray-100 px-1 shadow-sm">
                      {{
                        format(
                          new Date(appointment.vet_online),
                          'yyyy-MM-dd HH:mm'
                        )
                      }}
                    </span>
                  </p>
                </div>
              </div>
              <p v-if="!booking.veterinary" class="text-red font-semibold">
                No veterinarian assigned
              </p>
            </div>

            <div
              v-if="appointment.user"
              class="mt-8 flex flex-col justify-between"
            >
              <div class="mt-2">
                <div>
                  <p class="font-black">
                    {{ appointment.user.first_name }}
                    {{ appointment.user.last_name }}
                  </p>
                  <div class="mt-2">
                    <span>
                      <a
                        :href="'mailto:' + appointment.user.email"
                        class="break-all"
                      >
                        {{ appointment.user.email }}
                      </a>
                    </span>
                    <p class="mt-1">
                      {{ appointment.user.mobile_phone }}
                    </p>
                    <p v-if="appointment.device" class="mt-1">
                      {{ appointment.device }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="mt-8">
                <div class="mt-2">
                  <div>
                    <p class="font-semibold">
                      {{ appointment.animal ? appointment.animal.name : '--' }}
                    </p>
                    <div v-if="appointment.animal" class="mt-1">
                      <p>{{ appointment.animal.animal_type.name }}</p>
                      <p v-if="appointment.animal.birthdate">
                        {{
                          formatDistance(
                            new Date(appointment.animal.birthdate),
                            new Date(),
                            { addSuffix: false }
                          )
                        }}
                        old
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="appointment.description" class="mt-2">
                  <p class="text-gray-600">
                    {{ appointment.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="appointment" class="border-t bg-beigeLighter px-4 py-4">
      <slot name="button">
        <base-button
          color="light"
          class="w-full"
          :to="{ name: 'appointment', params: { id: appointment.id } }"
          target="_blank"
        >
          <span class="font-black">Go to appointment</span>
        </base-button>
      </slot>
    </div>
    <ReschedulingModal
      v-if="reschedule && appointment"
      :appointment="appointment"
      :booking="booking"
      @re-scheduled="handleRescheduling"
      @close="reschedule = false"
    />
    <ChangeVetModal
      v-if="changeVet && appointment"
      :appointment="appointment"
      :booking="booking"
      @close="changeVet = false"
      @re-scheduled="handleRescheduling"
    />
  </div>
</template>

<script>
import { format, formatDistance } from 'date-fns';
import { mapState } from 'vuex';
import ScheduleApi from '@/api/modules/admin/schedule';
import ChangeVetModal from '@/components/admin/schedule/ChangeVetModal';
import ReschedulingModal from '@/components/admin/schedule/ReschedulingModal';
import { VETPANEL_SV_URL } from '@/constants';

export default {
  components: {
    ReschedulingModal,
    ChangeVetModal,
  },
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
    inScheduler: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadState: 'idle',
      appointment: null,
      booking: null,
      reschedule: false,
      changeVet: false,
      format,
      formatDistance,
    };
  },
  computed: {
    ...mapState(['platform']),
    svAppointmentLink() {
      if (this.platform === 'intl') {
        return '#';
      }
      return `${VETPANEL_SV_URL}appointment/${this.appointment.id}`;
    },
  },
  watch: {
    bookingId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchAppointment();
      }
    },
  },
  mounted() {
    this.fetchAppointment();
  },
  beforeDestroy() {
    this.appointment = null;
    this.loadState = 'idle';
  },

  methods: {
    async fetchAppointment() {
      this.loadState = 'loading';
      const { data } = await ScheduleApi.fetchBooking(this.bookingId).catch(
        e => {
          console.error(e);
          this.loadState = 'error';
        }
      );
      this.loadState = 'idle';
      this.appointment = data.appointment;
      this.booking = data;
      this.$emit('fetched-appointment', data.appointment);
      return data;
    },
    async handleRescheduling() {
      this.reschedule = false;
      this.changeVet = false;
      await this.fetchAppointment();
      this.$emit('refresh-events');
    },
  },
};
</script>

<style lang="scss" scoped></style>
