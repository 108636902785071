var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: true },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "py-4" }, [
        _c("p", [
          _vm._v("Change vet for appointment #" + _vm._s(_vm.appointment.id)),
        ]),
        _c(
          "div",
          { staticClass: "flex items-center justify-between border-b py-4" },
          [
            _c("div", { staticClass: "text-lg" }, [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.format(
                        new Date(_vm.booking.booking_datetime),
                        "yyyy-MM-dd HH:mm"
                      )
                    ) +
                    " - "
                ),
                _vm.appointment.animal &&
                _vm.appointment.animal.name &&
                _vm.appointment.animal.animal_type
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.appointment.animal.name) +
                          " (" +
                          _vm._s(_vm.appointment.animal.animal_type.name) +
                          ") "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("p", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.appointment.user.first_name) +
                    " " +
                    _vm._s(_vm.appointment.user.last_name) +
                    " "
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingState === "idle",
                expression: "loadingState === 'idle'",
              },
            ],
            staticClass: "flex border-b py-4",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchStr,
                  expression: "searchStr",
                },
              ],
              ref: "searchStr",
              staticClass:
                "placholer:text-gray-900 border-gray-light focus:outline-none focus:bg-gray-lightest w-2/3 rounded border py-3 px-2 font-semibold focus:border-gray-800",
              attrs: { type: "search", placeholder: "Search veterinarian.." },
              domProps: { value: _vm.searchStr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchStr = $event.target.value
                },
              },
            }),
          ]
        ),
        _c(
          "div",
          { staticClass: "flex h-half-screen flex-col overflow-hidden" },
          [
            _vm.loadingState === "loading"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex h-full flex-col items-center justify-center",
                  },
                  [_c("base-spinner", { staticClass: "text-5xl" })],
                  1
                )
              : _vm._e(),
            _vm.loadingState === "confirm" && _vm.selectedVet
              ? _c("div", { staticClass: "py-8" }, [
                  _c(
                    "div",
                    { staticClass: "mt-4 mb-10 flex flex-wrap justify-center" },
                    [
                      _c("div", { staticClass: "w-full text-center" }, [
                        _vm._v("Change vet to"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 block text-center text-xl font-semibold",
                        },
                        [_vm._v(" " + _vm._s(_vm.selectedVet.name) + " ")]
                      ),
                      !_vm.scheduledVetsId.includes(_vm.selectedVet.id)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 block w-full text-center text-red-600",
                            },
                            [_vm._v(" (Not available) ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "mt-20 flex justify-between" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-900 hover:border-red-500",
                        on: { click: _vm.cancelConfirm },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "w-1/2 rounded border border-blue-500 bg-gray-900 px-2 py-2 text-white hover:bg-gray-800",
                        on: {
                          click: function ($event) {
                            return _vm.submitRescheduling()
                          },
                        },
                      },
                      [_vm._v(" Confirm ")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.loadingState === "error"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex h-full flex-col items-center justify-center",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-8 text-center font-semibold text-red-900",
                      },
                      [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-900 hover:border-red-500",
                        on: {
                          click: function ($event) {
                            _vm.loadingState = "idle"
                          },
                        },
                      },
                      [_vm._v(" Back ")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.loadingState === "idle"
              ? _c(
                  "ul",
                  { staticClass: "mt-2 overflow-auto" },
                  _vm._l(_vm.filteredVets, function (vet) {
                    return _c(
                      "li",
                      {
                        key: vet.id,
                        staticClass: "border-b py-4 pr-8 hover:bg-gray-200",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("p", { staticClass: "ml-4 font-semibold" }, [
                                  _vm._v(" " + _vm._s(vet.display_name) + " "),
                                  !_vm.scheduledVetsId.includes(vet.id)
                                    ? _c(
                                        "span",
                                        { staticClass: "text-red-600" },
                                        [_vm._v(" (Not available) ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "rounded border border-gray-900 px-2 py-2 text-gray-900 hover:border-black hover:text-black",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectVet(vet)
                                  },
                                },
                              },
                              [_vm._v(" Select ")]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }