var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: true },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "py-4" }, [
        _c(
          "p",
          { staticClass: "text-xs uppercase tracking-wider text-gray-800" },
          [
            _vm._v(" Reschedule appointment "),
            _c("span", { staticClass: "font-black" }, [
              _vm._v("#" + _vm._s(_vm.appointment.id)),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "flex items-center justify-between border-b py-4" },
          [
            _c("div", { staticClass: "text-lg" }, [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.format(
                        new Date(_vm.booking.booking_datetime),
                        "yyyy-MM-dd HH:mm"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.appointment.user.first_name) +
                    " " +
                    _vm._s(_vm.appointment.user.last_name) +
                    " - " +
                    _vm._s(_vm.appointment.animal.name) +
                    " (" +
                    _vm._s(
                      _vm.appointment.animal.animal_type
                        ? _vm.appointment.animal.animal_type.name
                        : "-"
                    ) +
                    ") "
                ),
              ]),
            ]),
          ]
        ),
        _vm.loadingState !== "confirm"
          ? _c(
              "div",
              {
                staticClass: "flex items-center justify-between border-b py-4",
              },
              [
                _c("div", { staticClass: "rounded-lg py-2 text-xl" }, [
                  _vm._v(
                    " " + _vm._s(_vm.format(_vm.startDay, "yyyy-MM-dd")) + " "
                  ),
                ]),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "hover:bg-gray-light focus:outline-none ml-1 inline-flex items-center rounded bg-gray-200 p-2 focus:ring",
                      class:
                        _vm.format(_vm.startDay, "yyyy-MM-dd") ===
                        _vm.format(new Date(), "yyyy-MM-dd")
                          ? "bg-gray-lightest pointer-events-none"
                          : "",
                      on: { click: _vm.fetchPrevDay },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-dark mr-2",
                        attrs: { icon: "caret-left" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "hover:bg-gray-light focus:outline-none ml-1 inline-flex items-center rounded bg-gray-200 p-2 focus:ring",
                      on: { click: _vm.fetchNextDay },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-dark ml-2",
                        attrs: { icon: "caret-right" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "flex flex-col overflow-hidden" }, [
          _vm.loadingState === "loading"
            ? _c(
                "div",
                {
                  staticClass:
                    "flex h-full flex-col items-center justify-center",
                },
                [_c("base-spinner", { staticClass: "text-5xl" })],
                1
              )
            : _vm._e(),
          _vm.loadingState === "confirm" && _vm.selectedSlot
            ? _c("div", { staticClass: "py-8" }, [
                _c("div", { staticClass: "mt-4 mb-10 flex" }, [
                  _c(
                    "p",
                    { staticClass: "text-center text-lg font-semibold" },
                    [_vm._v(" Confirm rescheduling ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex items-center justify-between" },
                  [
                    _c("div", { staticClass: "flex-1 text-base" }, [
                      _c(
                        "div",
                        { staticClass: "mx-auto inline-flex items-center" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mr-1 block text-xs text-gray-700",
                            attrs: { icon: "calendar-alt" },
                          }),
                          _c("span", { staticClass: "block" }, [
                            _vm._v(
                              _vm._s(
                                _vm.format(
                                  new Date(_vm.booking.booking_datetime),
                                  "yyyy-MM-dd HH:mm"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-1 inline-flex items-center" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mr-1 text-xs text-gray-700",
                            attrs: { icon: "user-md" },
                          }),
                          _vm.booking.veterinary
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.booking.veterinary.first_name) +
                                    " " +
                                    _vm._s(_vm.booking.veterinary.last_name) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          !_vm.booking.veterinary
                            ? _c("span", { staticClass: "text-red-light" }, [
                                _vm._v("Unassigned"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "inline-flex w-1/4 justify-center" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-3xl text-primary-lighter",
                          attrs: { icon: "arrow-alt-circle-right" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "w-1/2 py-4 text-lg" }, [
                      _c(
                        "div",
                        { staticClass: "flex items-center text-gray-700" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mr-1 text-xs",
                            attrs: { icon: "calendar-alt" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatInTimeZone(
                                  new Date(_vm.selectedSlot.dateTime.date),
                                  _vm.selectedSlot.dateTime.timezone,
                                  "yyyy-MM-dd HH:mm"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-1 flex items-center text-gray-700" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mr-1 text-xs",
                            attrs: { icon: "user-md" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.selectedSlot.user.first_name) +
                                " " +
                                _vm._s(_vm.selectedSlot.user.last_name)
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "mt-8 flex" }, [
                  _c(
                    "label",
                    {
                      staticClass: "inline-flex items-center",
                      attrs: { for: "sendEmails" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reschedule,
                            expression: "reschedule",
                          },
                        ],
                        attrs: { id: "sendEmails", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.reschedule)
                            ? _vm._i(_vm.reschedule, null) > -1
                            : _vm.reschedule,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.reschedule,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.reschedule = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.reschedule = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.reschedule = $$c
                            }
                          },
                        },
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Send notification emails"),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-8 flex items-center rounded bg-fv-gray-lighter py-3 px-2 text-gray-900 shadow",
                    class: _vm.reschedule ? "" : "line-through",
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-xs text-fv-gray",
                      attrs: { icon: "exclamation-circle" },
                    }),
                    _c("p", { staticClass: "ml-2" }, [
                      _vm._v(
                        " Both the customer and involved veterinarians will be notified of the change via email. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-20 flex justify-between" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-800 hover:border-red-500",
                      on: { click: _vm.cancelConfirm },
                    },
                    [_vm._v(" Back ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-1/2 rounded border bg-gray-900 px-2 py-2 text-white hover:bg-black",
                      on: {
                        click: function ($event) {
                          return _vm.submitRescheduling(_vm.selectedSlot)
                        },
                      },
                    },
                    [_vm._v(" Reschedule ")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.loadingState === "idle"
            ? _c(
                "ul",
                { staticClass: "mt-2 overflow-auto" },
                _vm._l(_vm.options, function (slot) {
                  return _c(
                    "li",
                    {
                      key: slot.id,
                      staticClass:
                        "border-gray-250 hover:bg-fv-gray-250 border-b py-4 pr-4",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-between" },
                        [
                          _c(
                            "div",
                            { staticClass: "inline-flex items-center" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-mono text-lg font-semibold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatInTimeZone(
                                          new Date(slot.dateTime.date),
                                          slot.dateTime.timezone,
                                          "HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mt-2 block text-xs font-normal text-gray-700",
                                    },
                                    [_vm._v(_vm._s(slot.dateTime.timezone))]
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "ml-6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(slot.user.first_name) +
                                    " " +
                                    _vm._s(slot.user.last_name) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "rounded border border-gray-900 px-4 py-2 text-gray-700 hover:border-black hover:text-black",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.rescheduleBooking(slot)
                                },
                              },
                            },
                            [_vm._v(" Select ")]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }