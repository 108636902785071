<template>
  <base-modal :show="true" @close="$emit('close')">
    <div class="py-4">
      <p class="text-xs uppercase tracking-wider text-gray-800">
        Reschedule appointment
        <span class="font-black">#{{ appointment.id }}</span>
      </p>
      <div class="flex items-center justify-between border-b py-4">
        <div class="text-lg">
          <p class="font-semibold">
            {{ format(new Date(booking.booking_datetime), 'yyyy-MM-dd HH:mm') }}
          </p>
          <p class="mt-1">
            {{ appointment.user.first_name }} {{ appointment.user.last_name }} -
            {{ appointment.animal.name }} ({{
              appointment.animal.animal_type
                ? appointment.animal.animal_type.name
                : '-'
            }})
          </p>
        </div>
      </div>
      <div
        v-if="loadingState !== 'confirm'"
        class="flex items-center justify-between border-b py-4"
      >
        <div class="rounded-lg py-2 text-xl">
          {{ format(startDay, 'yyyy-MM-dd') }}
        </div>
        <div>
          <button
            class="hover:bg-gray-light focus:outline-none ml-1 inline-flex items-center rounded bg-gray-200 p-2 focus:ring"
            :class="
              format(startDay, 'yyyy-MM-dd') ===
              format(new Date(), 'yyyy-MM-dd')
                ? 'bg-gray-lightest pointer-events-none'
                : ''
            "
            @click="fetchPrevDay"
          >
            <font-awesome-icon class="text-gray-dark mr-2" icon="caret-left" />
          </button>
          <button
            class="hover:bg-gray-light focus:outline-none ml-1 inline-flex items-center rounded bg-gray-200 p-2 focus:ring"
            @click="fetchNextDay"
          >
            <font-awesome-icon class="text-gray-dark ml-2" icon="caret-right" />
          </button>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden">
        <div
          v-if="loadingState === 'loading'"
          class="flex h-full flex-col items-center justify-center"
        >
          <base-spinner class="text-5xl" />
        </div>
        <div v-if="loadingState === 'confirm' && selectedSlot" class="py-8">
          <div class="mt-4 mb-10 flex">
            <p class="text-center text-lg font-semibold">
              Confirm rescheduling
            </p>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex-1 text-base">
              <div class="mx-auto inline-flex items-center">
                <font-awesome-icon
                  icon="calendar-alt"
                  class="mr-1 block text-xs text-gray-700"
                />
                <span class="block">{{
                  format(new Date(booking.booking_datetime), 'yyyy-MM-dd HH:mm')
                }}</span>
              </div>
              <div class="mt-1 inline-flex items-center">
                <font-awesome-icon
                  icon="user-md"
                  class="mr-1 text-xs text-gray-700"
                />
                <span v-if="booking.veterinary">
                  {{ booking.veterinary.first_name }}
                  {{ booking.veterinary.last_name }}
                </span>
                <span v-if="!booking.veterinary" class="text-red-light"
                  >Unassigned</span
                >
              </div>
            </div>
            <div class="inline-flex w-1/4 justify-center">
              <font-awesome-icon
                class="text-3xl text-primary-lighter"
                icon="arrow-alt-circle-right"
              />
            </div>
            <div class="w-1/2 py-4 text-lg">
              <div class="flex items-center text-gray-700">
                <font-awesome-icon icon="calendar-alt" class="mr-1 text-xs" />

                <span>{{
                  formatInTimeZone(
                    new Date(selectedSlot.dateTime.date),
                    selectedSlot.dateTime.timezone,
                    'yyyy-MM-dd HH:mm'
                  )
                }}</span>
              </div>
              <div class="mt-1 flex items-center text-gray-700">
                <font-awesome-icon icon="user-md" class="mr-1 text-xs" />

                <span
                  >{{ selectedSlot.user.first_name }}
                  {{ selectedSlot.user.last_name }}</span
                >
              </div>
            </div>
          </div>
          <div class="mt-8 flex">
            <label for="sendEmails" class="inline-flex items-center">
              <input
                id="sendEmails"
                v-model="reschedule"
                class=""
                type="checkbox"
              />
              <span class="ml-2">Send notification emails</span>
            </label>
          </div>

          <div
            class="mt-8 flex items-center rounded bg-fv-gray-lighter py-3 px-2 text-gray-900 shadow"
            :class="reschedule ? '' : 'line-through'"
          >
            <font-awesome-icon
              class="text-xs text-fv-gray"
              icon="exclamation-circle"
            />
            <p class="ml-2">
              Both the customer and involved veterinarians will be notified of
              the change via email.
            </p>
          </div>

          <div class="mt-20 flex justify-between">
            <button
              class="mr-2 w-1/2 rounded border border-red-500 px-2 py-2 text-red-800 hover:border-red-500"
              @click="cancelConfirm"
            >
              Back
            </button>
            <button
              class="w-1/2 rounded border bg-gray-900 px-2 py-2 text-white hover:bg-black"
              @click="submitRescheduling(selectedSlot)"
            >
              Reschedule
            </button>
          </div>
        </div>
        <ul v-if="loadingState === 'idle'" class="mt-2 overflow-auto">
          <li
            v-for="slot in options"
            :key="slot.id"
            class="border-gray-250 hover:bg-fv-gray-250 border-b py-4 pr-4"
          >
            <div class="flex items-center justify-between">
              <div class="inline-flex items-center">
                <p class="font-mono text-lg font-semibold">
                  {{
                    formatInTimeZone(
                      new Date(slot.dateTime.date),
                      slot.dateTime.timezone,
                      'HH:mm'
                    )
                  }}
                  <span class="mt-2 block text-xs font-normal text-gray-700">{{
                    slot.dateTime.timezone
                  }}</span>
                </p>
                <p class="ml-6">
                  {{ slot.user.first_name }} {{ slot.user.last_name }}
                </p>
              </div>
              <button
                class="rounded border border-gray-900 px-4 py-2 text-gray-700 hover:border-black hover:text-black"
                type="button"
                @click="rescheduleBooking(slot)"
              >
                Select
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ScheduleApi from '@/api/modules/admin/schedule';
import { format, startOfDay, addDays, isSameDay, subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format,
      formatInTimeZone,
      loadingState: 'idle',
      selectedSlot: null,
      reschedule: 1,
      index: 0,
      options: [],
      vetId: null,
      selectedDay: undefined,
    };
  },
  computed: {
    startDay: {
      get() {
        return (
          this.selectedDay ||
          new Date(
            formatInTimeZone(
              new Date(),
              this.countryTimezone,
              'yyyy-MM-dd HH:mm'
            )
          )
        );
      },
      set(val) {
        this.selectedDay = new Date(
          formatInTimeZone(val, this.countryTimezone, 'yyyy-MM-dd HH:mm')
        );
      },
    },
    ...mapGetters('admin', ['countryTimezone']),
  },
  mounted() {
    this.fetchAvailableSlots();
  },
  methods: {
    fetchNextDay() {
      this.startDay = startOfDay(addDays(this.startDay, 1));
      this.fetchAvailableSlots();
    },
    fetchPrevDay() {
      this.startDay = startOfDay(subDays(this.startDay, 1));
      const currentDate = new Date();
      if (isSameDay(this.startDay, currentDate)) {
        this.startDay = currentDate;
      }
      this.fetchAvailableSlots();
    },
    async fetchAvailableSlots() {
      this.loadingState = 'loading';
      const fromDate = this.startDay;
      const toDate = startOfDay(addDays(new Date(fromDate.getTime()), 1));

      let params = {
        fromDate: format(fromDate, 'yyyy-MM-dd'),
        fromTime: format(fromDate, 'HH:mm'),
        toDate: format(toDate, 'yyyy-MM-dd'),
        toTime: format(toDate, 'HH:mm'),
        ignoreMargin: 1,
        timezone: this.countryTimezone,
        animalTypeId: this.appointment.animal.animal_type.id,
        serviceId: this.booking.service_id,
        countryId: this.booking.country_id,
        random: 1,
      };
      if (this.vetId) {
        params = {
          ...params,
          vetId: this.vetId,
        };
      }
      const { data } = await ScheduleApi.fetchAvailableSlots(params);
      this.loadingState = 'idle';
      this.options = data;
    },
    rescheduleBooking(slot) {
      this.loadingState = 'confirm';
      this.selectedSlot = slot;
    },
    async submitRescheduling(slot) {
      this.loadingState = 'loading';
      const { serviceId, dateTime, user } = slot;
      const params = {
        booking_datetime: formatInTimeZone(
          new Date(dateTime.date),
          dateTime.timezone,
          'yyyy-MM-dd HH:mm:ss'
        ),
        service_id: serviceId,
        user_id: user.id,
        country_id: this.booking.country.id,
        reschedule: this.reschedule,
      };
      await ScheduleApi.updateBooking(this.booking.id, params).catch(e => {
        console.error(e);
        throw Error(e);
      });

      this.selectedSlot = null;
      this.loadingState = 'idle';
      this.$emit('re-scheduled');
      this.$notify({
        group: 'primary',
        title: 'Booking rescheduled',
      });
    },
    cancelConfirm() {
      this.selectedSlot = null;
      this.loadingState = 'idle';
    },
  },
};
</script>
