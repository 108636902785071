var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "min-w-md flex h-full flex-col justify-between overflow-hidden rounded-lg border bg-white text-gray-900 shadow",
    },
    [
      _c("div", { staticClass: "overflow-auto p-4" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between text-gray-700" },
          [
            _c("div", [
              _vm.appointment && _vm.loadState !== "loading"
                ? _c("span", { staticClass: "font-mono" }, [
                    _vm._v("#" + _vm._s(_vm.appointment.id)),
                  ])
                : _vm._e(),
            ]),
            _vm.inScheduler
              ? _c("span", [
                  _c(
                    "button",
                    {
                      attrs: { color: "cancel", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeinfo")
                        },
                      },
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "text-gray-600",
                        attrs: { icon: "chevron-right" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "flex flex-col justify-center pt-4",
            class: { "text-sm": _vm.inScheduler },
          },
          [
            _vm.loadState === "loading"
              ? _c(
                  "div",
                  { staticClass: "mx-auto mt-8" },
                  [_c("base-spinner", { staticClass: "mx-auto text-3xl" })],
                  1
                )
              : _vm._e(),
            _vm.booking && _vm.loadState === "idle"
              ? _c("div", { staticClass: "flex flex-col justify-between" }, [
                  _c("div", { staticClass: "border-b border-dotted pb-4" }, [
                    _c("p", { staticClass: "mb-1 text-lg font-black" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.format(
                              new Date(_vm.booking.booking_datetime),
                              "HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", {
                      staticClass: "text-gray-700",
                      class: { "text-xs": _vm.inScheduler },
                    }),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.format(
                              new Date(_vm.booking.booking_datetime),
                              "EEEE, MMMM do yyyy"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _vm.appointment.case_type
                      ? _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.appointment.case_type.name) + " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.inScheduler
                    ? _c("div", { staticClass: "py-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center",
                            class: { "text-xs": _vm.inScheduler },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "focus:outline-none mr-4 font-bold text-primary-darker hover:text-primary-darkest",
                                on: {
                                  click: function ($event) {
                                    _vm.reschedule = true
                                  },
                                },
                              },
                              [_vm._v(" Reschedule ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "focus:outline-none font-bold text-primary-darker hover:text-primary-darkest",
                                on: {
                                  click: function ($event) {
                                    _vm.changeVet = true
                                  },
                                },
                              },
                              [_vm._v(" Change vet ")]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "mt-2 flex text-gray-800",
                      class: { "text-xs": _vm.inScheduler },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "inline-flex items-center space-x-1" },
                        [
                          _vm.appointment.journal_status
                            ? _c("span", [_vm._v("Signed")])
                            : _vm._e(),
                          !_vm.appointment.journal_status
                            ? _c("span", [_vm._v("Not signed")])
                            : _vm._e(),
                          _c("fv-icon", {
                            class: _vm.appointment.journal_status
                              ? "text-green-600"
                              : "text-yellow-600",
                            attrs: {
                              size: "xs",
                              icon: _vm.appointment.journal_status
                                ? "check-mark-alt"
                                : "warning",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "pt-4" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _vm.booking.veterinary
                        ? _c("div", { staticClass: "mt-2" }, [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm.booking.veterinary.display_name
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.booking.veterinary.display_name
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.booking.veterinary.first_name
                                      ) +
                                        " " +
                                        _vm._s(_vm.booking.veterinary.last_name)
                                    ),
                                  ]),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.booking.veterinary.mobile_phone_formatted
                                )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(_vm._s(_vm.booking.veterinary.email)),
                            ]),
                            _vm.appointment.vet_online
                              ? _c("div", { staticClass: "mt-2 text-sm" }, [
                                  _c(
                                    "p",
                                    { staticClass: "mb-1 text-gray-700" },
                                    [
                                      _vm._v(" Online at "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "rounded border bg-gray-100 px-1 shadow-sm",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.format(
                                                  new Date(
                                                    _vm.appointment.vet_online
                                                  ),
                                                  "yyyy-MM-dd HH:mm"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      !_vm.booking.veterinary
                        ? _c("p", { staticClass: "text-red font-semibold" }, [
                            _vm._v(" No veterinarian assigned "),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.appointment.user
                      ? _c(
                          "div",
                          { staticClass: "mt-8 flex flex-col justify-between" },
                          [
                            _c("div", { staticClass: "mt-2" }, [
                              _c("div", [
                                _c("p", { staticClass: "font-black" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.appointment.user.first_name) +
                                      " " +
                                      _vm._s(_vm.appointment.user.last_name) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "mt-2" }, [
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "break-all",
                                        attrs: {
                                          href:
                                            "mailto:" +
                                            _vm.appointment.user.email,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.appointment.user.email) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("p", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.appointment.user.mobile_phone
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm.appointment.device
                                    ? _c("p", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.appointment.device) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "mt-8" }, [
                              _c("div", { staticClass: "mt-2" }, [
                                _c("div", [
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.appointment.animal
                                            ? _vm.appointment.animal.name
                                            : "--"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm.appointment.animal
                                    ? _c("div", { staticClass: "mt-1" }, [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.appointment.animal.animal_type
                                                .name
                                            )
                                          ),
                                        ]),
                                        _vm.appointment.animal.birthdate
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDistance(
                                                      new Date(
                                                        _vm.appointment.animal.birthdate
                                                      ),
                                                      new Date(),
                                                      { addSuffix: false }
                                                    )
                                                  ) +
                                                  " old "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm.appointment.description
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _c("p", { staticClass: "text-gray-600" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.appointment.description) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.appointment
        ? _c(
            "div",
            { staticClass: "border-t bg-beigeLighter px-4 py-4" },
            [
              _vm._t("button", function () {
                return [
                  _c(
                    "base-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        color: "light",
                        to: {
                          name: "appointment",
                          params: { id: _vm.appointment.id },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", { staticClass: "font-black" }, [
                        _vm._v("Go to appointment"),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.reschedule && _vm.appointment
        ? _c("ReschedulingModal", {
            attrs: { appointment: _vm.appointment, booking: _vm.booking },
            on: {
              "re-scheduled": _vm.handleRescheduling,
              close: function ($event) {
                _vm.reschedule = false
              },
            },
          })
        : _vm._e(),
      _vm.changeVet && _vm.appointment
        ? _c("ChangeVetModal", {
            attrs: { appointment: _vm.appointment, booking: _vm.booking },
            on: {
              close: function ($event) {
                _vm.changeVet = false
              },
              "re-scheduled": _vm.handleRescheduling,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }